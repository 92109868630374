import React from "react";

export const Team = (props) => {
const divStyle = {
width: '100%', 
margin: 'auto', 
border: '3px solid #73AD21'
};
const frameStyle = {
border: '0'
};
  return (
    <div id="team" className="text-center">
      <div className="container">
          <h2>Welcome to the Video Meeting</h2>

       <div id="block1" style={divStyle}>
	 <iframe id="outer_iframe" title="room11" seamless scrolling="auto"
            src="https://s89.scisbo.com/frame.html" allow="geolocation;camera; microphone"          
             width="100%" height="800px" frameborder="0" style={frameStyle} allowfullscreen>
         </iframe>
        </div>

      </div>
    </div>
  );
};
